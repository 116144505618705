import { GoogleSpreadsheet } from "google-spreadsheet";
import { JWT } from "google-auth-library";
import creds from "../northumbria-project-8c797f2c969e.json";

const SCOPES = ["https://www.googleapis.com/auth/spreadsheets"];

const serviceAccountAuth = new JWT({
  // env var values here are copied from service account credentials generated by google
  // see "Authentication" section in docs for more info
  //   email: process.env.GOOGLE_SERVICE_ACCOUNT_EMAIL,
  //   key: process.env.GOOGLE_PRIVATE_KEY,

  email: creds.client_email,
  key: creds.private_key,
  scopes: SCOPES,
});

const doc = new GoogleSpreadsheet(
  "1j4JL35wd6tZ5IH02WyQ8dTHPuuPr7EqfQebCg4yHkRQ",
  serviceAccountAuth
);

export default doc;
